import * as React from 'react';
import { Box, Flex, Text } from '@awning/components';
import { ReactComponent as SVG404 } from '@/public/404.svg';

export default function Custom404({ blah }) {
  return (
    <Flex
      sx={{
        margin: '0 auto',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box as={SVG404} sx={{ mb: 4 }} />
      <Text
        sx={{
          text: '2xl',
          fontWeight: 'bold',
          my: 4,
        }}
      >
        {blah} We can't find this page.
      </Text>
      <Text>The page you're looking for doesn't seem to exist.</Text>
      <Box sx={{ mt: 5 }}>
        <a href="/feed">Go to Feed</a>
      </Box>
    </Flex>
  );
}
